import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
       <div>
         <footer>
          <div className="container">
            <img className="footer_logo" src="img/logo.png" alt="" />
           
            <hr />
            <p>&copy; 2021 Sun Moon All Rights Reserved. - <span className="color_2">Powered by tronbinary.org</span></p>
          </div>
        </footer>


        <div className="overlay">
          <div className="overlayDoor"></div>
          <div className="overlayContent">
            <div className="loader-logo">
              <div className="loader-circle"></div>
            </div>
          </div>
        </div>
       </div>
      );
    }
  }

  export default Footer;